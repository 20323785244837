import React from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavTitle,
  NavRight,
  Link,
} from 'framework7-react';
import Categories from "./Categories";

// export default ({ f7router, f7route, slug }) => {
export default (props) => {
  return (
      <Page name="home">
        <Navbar>
          <NavLeft>
            {/*<Link iconIos="f7:menu" iconMd="material:menu" panelOpen="left"></Link>*/}
            <NavTitle>{ props.userData.company_name || "Yükleniyor.." }</NavTitle>
          </NavLeft>
          {/*<NavTitle>My App</NavTitle>*/}
          <NavRight>
            <Link
                iconMaterial="menu"
                panelOpen="right"
            />
          </NavRight>
        </Navbar>
        <Categories { ...props } />
      </Page>
  );
}
