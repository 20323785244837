import React from 'react';
import { Page, Navbar, Block } from 'framework7-react';

export default ({ f7router, f7route }) => (
  <Page name="notfound">
    <Navbar title="Not found" backLink="Back" />
    <Block strong>
      <p>Üzgünüm!</p>
      <p>Aradığınız sayfa bulunamadı! Kaybolmuş olabilirsin.</p>
    </Block>
  </Page>
);
