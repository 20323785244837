import React from 'react';
import {
  App, View, Views,
} from 'framework7-react';
import routes from "../routes"

const f7params = {
  name: 'MenuCepte QR Kodlu Menu',
  id: 'com.menucepte.menu',
  pushState: true,
  routes,
  theme: 'ios',
  autoDarkTheme: true,
  darkTheme: true,
};

export default (props) => {
  return (
      <App {...f7params} params={f7params} class="theme-dark">
        <Views tabs className="safe-areas">
          <View id="view-today" main tab tabActive/>
        </Views>
      </App>
  )
}