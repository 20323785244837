import AboutPage from './components/pages/AboutPage';
import NotFoundPage from './components/pages/NotFoundPage';
import Render from "./components/Render";

export default [
  {
    path: '/about',
    component: AboutPage,
    options: {
      props: {
        foo: 'bar',
        bar: true,
      },
    },
  },
  {
    path: '/:slug',
    component: Render
  },
  {
    path: '/login/',
    component: NotFoundPage,
  },
]