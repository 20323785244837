import React, { useEffect, useState } from "react";
import AboutPage from "./pages/AboutPage";
import axios from "axios";
import HomePage from "./pages/HomePage";
import { Link, Toolbar } from "framework7-react";
import NotFoundPage from "./pages/NotFoundPage";

function Render({ slug, f7router }) {
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    axios.get(`https://beta.menucepte.com/api/v1/getUserBySlug/${slug}`)
    // axios.get(`http://localhost:8000/api/v1/getUserBySlug/${slug}`)
    .then(res => setUserData(res.data))
    .catch(res => setIsError(true))
    .finally(() => setIsLoading(false))
  }, [])

  if (isError) {
    return <NotFoundPage/>
  }

  const getPageComponent = () => {
    if (slug === "about") {
      return <AboutPage/>
    }
    return <HomePage userData={userData} isLoading={isLoading}/>
  }

  return (
      <>
        <Toolbar tabbar labels bottom>
          <Link
              tabLink="#tab-1"
              href="/"
              onclick={() => f7router.navigate(`/${slug}/`)}
              text="MENÜ"
              iconMaterial="restaurant_menu"
          />
          <Link
              tabLink="#tab-1"
              text="DUYURULAR"
              iconMaterial="rss_feed"
          />
          <Link
              tabLink="#tab-1"
              text="PAYLAŞ"
              iconMaterial="share"
          />
          <Link
              tabLink="#about"
              href="/about/"
              text="RESTAURANT"
              onClick={() => f7router.navigate('/about/')}
              iconMaterial="info"
          />
          <Link
              tabLink="#tab-3"
              text="İLETİŞİM"
              onClick={() => f7router.navigate('/404/')}
              iconMaterial="phone_in_talk"
          />
        </Toolbar>
        {getPageComponent()}
      </>
  )

}

export default Render