import React, { useState } from 'react';

import {
  List,
  ListItem,
  Page,
  BlockTitle, Popup, NavRight, Navbar, Block, Link, SkeletonBlock, Chip, NavTitle, NavLeft
} from 'framework7-react';

import './style.css';
import logo from "../../../assets/logo.png"

function Categories({ isLoading, userData }) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [popupDetail, setPopupDetail] = useState({})

  if (isLoading) {

    return (
        <>
          <List mediaList className="skeleton-text">
            <ListItem
                title="Title"
                subtitle="Subtitle"
                className={`skeleton-text skeleton-effect-fade`}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
            >
              <SkeletonBlock
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                  slot="media"
              />
            </ListItem>
            <ListItem
                title="Title"
                subtitle="Subtitle"
                className={`skeleton-text skeleton-effect-fade`}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
            >
              <SkeletonBlock
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                  slot="media"
              />
            </ListItem>
          </List>
          <List mediaList className="skeleton-text">
            <ListItem
                title="Title"
                subtitle="Subtitle"
                className={`skeleton-text skeleton-effect-fade`}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
            >
              <SkeletonBlock
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                  slot="media"
              />
            </ListItem>
            <ListItem
                title="Title"
                subtitle="Subtitle"
                className={`skeleton-text skeleton-effect-fade`}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
            >
              <SkeletonBlock
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                  slot="media"
              />
            </ListItem>
          </List>
        </>
    )
  }

  const onClickItem = (product) => {
    setPopupDetail(product)
    setPopupOpened(true)
  }


  return (
      <Page ptr ptrMousewheel={true}>
        {userData.categories.map(category => {
          if (!category.products) return ""
          return (
              <>
                <BlockTitle>{category.name}</BlockTitle>
                <List mediaList key={category.id}>
                  {category.products.map(product => {
                    return (
                        <ListItem
                            link="#"
                            title={product.name}
                            // after="İncele"
                            onClick={() => onClickItem(product)}
                            key={product.id}
                            // text={product.description}
                            text={(Array(product.ingredients) || []).join(", ") || ""}
                            // subtitle={(Array(product.ingredients) || []).join(", ") || ""}
                            badge={`${product.price},00 TL`}
                            badgeColor="deeporange"
                        >
                          {/*<img*/}
                          {/*    slot="media"*/}
                          {/*    src={`https://ui-avatars.com/api/?name=${product.name}`}*/}
                          {/*    width="80"*/}
                          {/*/>*/}
                        </ListItem>
                    )
                  })}
                </List>
              </>
          )
        })}

        <img src={logo} alt={"MenuCepte Logo"} style={{ display: "block", margin: "auto", marginTop: 10, width: "64%" }}/>

        <Popup
            className="demo-popup"
            opened={popupOpened}
            onPopupClosed={() => setPopupOpened(false)}
        >
          <Page>
            <Navbar>
              <NavLeft>
                <NavTitle>{popupDetail.name}</NavTitle>
              </NavLeft>
              <NavRight>
                <Link popupClose>Kapat</Link>
              </NavRight>
            </Navbar>

            <div className={"product-image"} style={{ backgroundImage: popupDetail.image ? `url(${popupDetail.image})` : "" }}/>

            {
              popupDetail.description &&
              <>
                <BlockTitle>Açıklama</BlockTitle>
                <Block strong>
                  <p>{popupDetail.description}</p>
                </Block>
              </>
            }

            <BlockTitle>Detaylar</BlockTitle>
            <List>
              <ListItem title="Fiyat" after={`${popupDetail.price}.00 TL`}/>
              {
                popupDetail.calory &&
                <ListItem title="Kalori" after={`${popupDetail.calory} kcal`}/>
              }
            </List>
            {
              popupDetail.ingredients &&
              <>
                <BlockTitle>İçindekiler</BlockTitle>
                <Block strong>
                  {(popupDetail.ingredients || "").split(", ").map(el => {
                    return <Chip text={el}/>
                  })}
                </Block>
              </>
            }
          </Page>
        </Popup>
      </Page>
  )
}

export default Categories